

import {Trans, useTranslation} from "next-i18next";
import Link from 'next/link'

export const RoadmapLink = () => {
    const {t} = useTranslation();
  return (
        <header className="bg-[#6F1515] w-full text-white px-4 lg:px-12 ">
            <div className="relative flex flex-row  py-2.5 lg:py-2.5 mx-auto ">
                <div className={`flex-1 flex flex-col items-center justify-center  space-y-2 text-center`}>

                    <span className="text-base  ">
                        <Trans
                            i18nKey="roadmap_link.text"
                            values={{}}
                            components={{ br: <br/> }}
                        />
                    </span>

                    <a href={`https://cryptocomics.world`} target="_blank" rel="noopener noreferrer" className={`text-yellow-300 font-bold`} >{t(`roadmap_link.link`)}</a>

                </div>

            </div>
        </header>
  )
}
