
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import styles from './index.module.css'

import {COMICS_SERIES} from '../config/meta'
import {BlockNewIssue, CollectIssues, SeriaPreview, Subscribe} from "../components/block";
import {RoadmapLink} from "../components/block/roadmaplink";
import Head from "next/head";
import {useTranslation} from "next-i18next";


export async function getStaticProps({ locale }) {
    return {
        props: {
            ...(await serverSideTranslations(locale)),
        },
    };
}

export default function Home() {
    const {t} = useTranslation();
  return (
    <div className={styles.container}>
        <Head>
            <title>{t(`project_name`)}</title>
        </Head>
        <RoadmapLink />
        <BlockNewIssue />

        <div className={`w-full pl-4 pr-4 py-4  lg:pl-12 lg:pr-12 lg:py-12 ${styles.pagebg} `}>
            <CollectIssues className={`mb-8 md:mb-12`} />
            {COMICS_SERIES.map((seria, idx)=><SeriaPreview key={seria.id} seria={seria} className={`mt-4 lg:mt-8`} />)}
        </div>

    </div>
  )
}